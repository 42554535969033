/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    h2: "h2",
    div: "div",
    h3: "h3",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "An integration automation platform helps apps connect to each other. An ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), " also helps apps connect to each other. So, if you need to connect apps, which one do you use? Let's look at two app connection scenarios and go from there."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Your company's accounting system needs to update your CRM account data with payment data. This update ensures that the account in CRM displays whether accounts receivable are on time or past due."), "\n", React.createElement(_components.li, null, "You have dozens of customers who use your SaaS app (an HR package) to manage their employees. Each of these customers has separate payroll and performance tracking apps. They need to hook your HR product into those additional apps."), "\n"), "\n", React.createElement(_components.p, null, "For each of these scenarios, there is a best answer. We'll return to them soon, but first, let's look at the big picture."), "\n", React.createElement(_components.h2, {
    id: "why-is-integration-automation-essential-for-saas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-is-integration-automation-essential-for-saas",
    "aria-label": "why is integration automation essential for saas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why is integration automation essential for SaaS?"), "\n", React.createElement(_components.p, null, "The number of SaaS apps keeps growing, with one recent report finding ", React.createElement(_components.a, {
    href: "https://ascendixtech.com/number-saas-companies-statistics/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "more than 30,000 SaaS companies worldwide"), ". And that doesn't even factor in the subset of these companies with multiple apps."), "\n", React.createElement(_components.p, null, "Historically, it was common for users to look up data in one app and then enter it into a second or third app. Or they copied data from one app and dropped it into an Excel sheet or transformation tool to filter, combine, or modify the data for use in a second app."), "\n", React.createElement(_components.p, null, "But these manual approaches to automation and integration are very inefficient. With thousands of apps and millions of customers using those apps, we need SaaS integration automation – not only for businesses to connect apps internal to their enterprise but also for B2B SaaS companies to be able to provide integrations between their products and the other apps their customers use."), "\n", React.createElement(_components.h3, {
    id: "what-is-integration-automation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-integration-automation",
    "aria-label": "what is integration automation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is integration automation?"), "\n", React.createElement(_components.p, null, "What is integration automation? Integration automation is defined as establishing data connections between systems that execute as needed without regular human intervention. That is, integrations are set up so that the systems can automatically share data with each other based on pre-defined rules."), "\n", React.createElement(_components.p, null, "As noted, much data sharing traditionally required users to copy data from one system to another manually. Integration automation relies upon software (integrations) to automate these data transfers, freeing users to perform more essential tasks."), "\n", React.createElement(_components.p, null, "Let's look at some tools you could use to enable integration automation for SaaS apps."), "\n", React.createElement(_components.h2, {
    id: "what-is-an-integration-automation-platform",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-an-integration-automation-platform",
    "aria-label": "what is an integration automation platform permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is an integration automation platform?"), "\n", React.createElement(_components.p, null, "An integration automation platform (also called an iPaaS or enterprise iPaaS) is a solution that enables the development, deployment, and support of integrations within your company. This platform supports workflow automation by connecting apps to ensure data is shared automatically between business systems internal to the company."), "\n", React.createElement(_components.p, null, "The platform has connectors and other tools to enable integrations among commonly available enterprise systems. It also allows developers to build integrations with other applications. Integration automation platforms have existed in one form or another for many years."), "\n", React.createElement(_components.p, null, "The critical thing to remember for integration automation platforms is that they're general-purpose platforms used by ", React.createElement(_components.em, null, "businesses"), " to create integrations for ", React.createElement(_components.em, null, "internal use"), "."), "\n", React.createElement(_components.h3, {
    id: "integration-automation-platform-use-cases",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integration-automation-platform-use-cases",
    "aria-label": "integration automation platform use cases permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integration automation platform use cases"), "\n", React.createElement(_components.p, null, "The scenario with your accounting and CRM systems is an excellent example of the base use case for an integration automation platform. Data from one system within your company needs to be shared with another system in the enterprise."), "\n", React.createElement(_components.p, null, "Whether the applications you need to connect are traditional client-server systems or SaaS apps doesn't matter. An iPaaS is built to connect to any system within the enterprise that has an API, supports file transfers, uses a messaging system, or some other means of data exchange."), "\n", React.createElement(_components.h2, {
    id: "what-is-an-embedded-ipaas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-an-embedded-ipaas",
    "aria-label": "what is an embedded ipaas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is an embedded iPaaS?"), "\n", React.createElement(_components.p, null, "An ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platform"), ", or embedded iPaaS, is a set of tools that enables software companies to build reusable, configurable, native integrations and deliver them to customers as a seamless part of your SaaS product."), "\n", React.createElement(_components.p, null, "In addition to an integration automation platform's features (integration designer, connectors, and infrastructure), an embedded iPaaS includes integration deployment and customer support tools, customer management, an embedded integration marketplace, and an embedded integration designer for end users."), "\n", React.createElement(_components.p, null, "The critical thing to remember for embedded iPaaS solutions is that they are purpose-built platforms used by ", React.createElement(_components.em, null, "software companies"), " to create native product integrations for ", React.createElement(_components.em, null, "their customers.")), "\n", React.createElement(_components.h3, {
    id: "embedded-ipaas-use-cases",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#embedded-ipaas-use-cases",
    "aria-label": "embedded ipaas use cases permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Embedded iPaaS use cases"), "\n", React.createElement(_components.p, null, "The scenario with the HR package (your B2B app) that dozens of your customers use is an excellent example of the base use case for an embedded iPaaS. In simple terms, your B2B SaaS app needs to share data with your customer's other apps."), "\n", React.createElement(_components.p, null, "For a second use case, we'll say your SaaS product is an eCommerce system. Your customers want to know when one of their orders is created in that system. One subset of your customers would like to receive Slack messages, and another would like to get email notifications. A third subset of customers would like the order notifications written to a file and placed into a file share. You could handle all these needs with a single integration that uses customer-specific configuration options."), "\n", React.createElement(_components.p, null, "For yet another use case, your SaaS product is a CRM. Several customers need to send order data to the CRM, but they are using legacy order systems without APIs. As a result, you need an integration that can import daily CSV files from these different order systems and update your SaaS product accordingly."), "\n", React.createElement(_components.p, null, "As with an enterprise iPaaS, an embedded iPaaS is designed to connect all sorts of apps (both client-server and cloud-based). However, an embedded iPaaS connects apps for your customers' benefit rather than the benefit of your internal systems."), "\n", React.createElement(_components.h2, {
    id: "how-to-choose-between-an-integration-automation-platform-and-embedded-ipaas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-choose-between-an-integration-automation-platform-and-embedded-ipaas",
    "aria-label": "how to choose between an integration automation platform and embedded ipaas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to choose between an integration automation platform and embedded iPaaS?"), "\n", React.createElement(_components.p, null, "How do you determine which platform is best for your integration project? Well, are you attempting to solve an internal integration issue (for your company) or an external integration issue (for your customers)?"), "\n", React.createElement(_components.p, null, "Suppose you are solving for internal integrations: how to get data from one system to another within your company. In that case, you probably should look at an integration automation platform."), "\n", React.createElement(_components.p, null, "On the other hand, if you need to solve the integration question for your customers (how to share data from your SaaS product with the other apps your customers use), you should be using an embedded iPaaS. To review the criteria and questions for evaluating an embedded iPaaS, check out our full post on ", React.createElement(_components.a, {
    href: "../how-to-choose-embedded-ipaas-for-your-saas/"
  }, "how to choose an embedded iPaaS"), "."), "\n", React.createElement(_components.h2, {
    id: "use-the-integration-platform-that-meets-your-needs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-the-integration-platform-that-meets-your-needs",
    "aria-label": "use the integration platform that meets your needs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use the integration platform that meets your needs"), "\n", React.createElement(_components.p, null, "Integration automation is essential inside and outside the enterprise. After all, almost every app needs to share data with another app. But use cases vary, and you should use the right tool for the issue you are trying to solve."), "\n", React.createElement(_components.p, null, "Yes, some companies have attempted to support their customers' B2B SaaS integrations with an enterprise iPaaS, but the results aren't pretty. Similarly, an ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), " is not the most efficient solution to address purely internal integrations."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/contact/"
  }, "Contact us"), " to discuss your use case and see if Prismatic's embedded iPaaS is the right platform for you."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
